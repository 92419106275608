<template>
  <div class="settings">
    <TopTabbar title="个人设置" text="返回"/>
    <div class="center">
      <div class="userInfo" @click="userInfo">
        <img :src="User.avatar">
        <div class="info">
          <h1>{{ User.nickname }}</h1>
          <p>账号:{{ User.username }}</p>
        </div>
        <span></span>
      </div>
      <div class="item" @click="updatePsw">
        <span>修改密码</span>
        <span></span>
      </div>
      <div class="item" @click="policy">
        <span>隐私政策</span>
        <span></span>
      </div>
      <div class="item" @click="agreement">
        <span>服务协议</span>
        <span></span>
      </div>
      <div class="item" @click="about">
        <span>关于我们</span>
        <span></span>
      </div>
      <van-button type="primary" round @click="exit">退出登陆</van-button>
    </div>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router'
  import { reqUser } from '../../../API/user'
  import { reqLogOut } from '../../../API/login'
  import { onMounted, reactive } from 'vue'
  import { showConfirmDialog } from 'vant';

  const $router =  useRouter()
  const User = reactive({})

  onMounted(async () => {
    const result = await reqUser()
    if(result.code == 1) Object.assign(User,result.data)
  })

  //用户详情
  const userInfo = () => {
    $router.push('/user/details')
  }

  //修改密码
  const updatePsw = () => {
    $router.push('/user/resetPassword')
  }

  //隐私政策
  const policy = () => {
    $router.push('/user/policy')
  }

  //服务协议
  const agreement = () => {
    $router.push('/user/agreement')
  }

  //关于我们
  const about = () => {
    $router.push('/user/about')
  }

  //退出登陆
  const exit = () => {
    showConfirmDialog({
      message:'确认要退出吗？',
    })
    .then(async () => {
      console.log('qweqweqw');
      const result = await reqLogOut()
      if(result.code == 1){
        localStorage.clear()
        $router.push('/login')
      }
    })
    .catch(() => {});
  }
</script>

<style lang="scss" scoped>
  .settings{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #f8f8f8;
    .center{
      width: 100%;
      height: calc(100% - 46px);
      .userInfo{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 15px;
        background-color: white;
        height: 91px;
        margin: 10px 0;
        img{
          width: 51px;
          height: 51px;
          border-radius: 50%;
          background-color: aqua;
          margin-right: 15px;
        }
        .info{
          flex: 1;
          text-align: left;
          h1{
            font-size: 18px;
          }
          p{
            font-size: 16px;
            margin-top: 10px;
          }
        }
        
        span{
          width: 10px;
          height: 10px;
          border-top: 2px solid #7b7b7b;
          border-right: 2px solid #7b7b7b;
          transform: rotate(45deg);
        }
      }
      .item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 20px 15px;
        background-color: white;
        font-size: 16px;
        &:not(:first-of-type){
          margin-bottom: 2px;
        }
        span{
          &:nth-of-type(2){
            width: 10px;
            height: 10px;
            border-top: 2px solid #7b7b7b;
            border-right: 2px solid #7b7b7b;
            transform: rotate(45deg);
          }
        }
      }
      .van-button{
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 30px;
      }
    }
  }
</style>